export default {
  toolBarConfig: [
    // {
    //   type: 'button',
    //   buttonType: 'primary',
    //   icon: 'el-icon-plus',
    //   code: 'add',
    //   name: '新增',
    // },
    // {
    //   type: 'button',
    //   buttonType: 'edit',
    //   icon: 'el-icon-circle-check',
    //   code: 'edit',
    //   name: '全选',
    // },
    // {
    //   type: 'button',
    //   buttonType: 'edit',
    //   icon: 'el-icon-delete',
    //   code: 'delete',
    //   name: '删除',
    // },
  ],
  columns: [
    // {
    //   fixed: 'left', // 固定位置在左边
    //   type: 'checkbox', // 多选
    //   title: null,
    //   width: '50',
    // },
    // // {
    // //   fixed: 'left',
    // //   type: 'seq', // 显示序号
    // //   title: '序号',
    // // },
    // {
    //   field: 'url',
    //   title: '图片',

    // },
    // {
    //   field: 'remarks',
    //   title: '图片描述',
    // },
    // {
    //   field: 'sortNum',
    //   title: '图片排序',
    // },
    // {
    //   field: 'url',
    //   title: '绝对路径',
    // },
    // {
    //   field: 'fileName',
    //   title: '图片名称',
    // },
    // {
    //   field: 'createName',
    //   title: '创建人',
    // },
    // {
    //   field: 'createDate',
    //   title: '创建时间',
    // },
    // {
    //   field: 'updateName',
    //   title: '最近更新人',
    // },
    // {
    //   field: 'updateDate',
    //   title: '最近更新时间',
    // },
    // {
    //   title: '操作',
    //   width: '120',
    //   align: 'center',
    //   buttons: [
    //     {
    //       type: 'button',
    //       buttonType: 'primary',
    //       code: 'edit',
    //       name: '编辑',
    //       props: {
    //         type: 'primary',
    //       },
    //     },
    //     {
    //       type: 'button',
    //       buttonType: 'primary',
    //       code: 'config',
    //       name: '查看',
    //       props: {
    //         type: 'primary',
    //       },
    //     },
    //     {
    //       type: 'dropdown',
    //       buttonType: 'link',
    //       code: 'delete',
    //       name: '删除',
    //       props: {},
    //     },
    //   ],
    // },
  ],
  tableConfig: {
    border: true,
    treeConfig: {
      children: 'children',
    },
    idKey: 'functionCode', // 默认勾选判断字段
    resizable: true, // 所有的列是否允许拖动列宽调整大小
    showOverflow: true, // 当内容过长时显示为省略号
    keepSource: false, // 保持原始值的状态，被某些功能所依赖，比如编辑状态、还原数据等（开启后影响性能，具体取决于数据量）
    highlightHoverRow: true, // 鼠标滑动高亮
    // height: '600',
  },
};
