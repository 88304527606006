<!--
 * @Author: yzr
 * @Date: 2020-11-24 10:02:48
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-17 15:32:21
-->
<script>
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import configs from './data';
import Form from '../form';

export default {
  extends: TablePage,
  components: {
    Modal,
    Form,
  },
  data() {
    return {
      requestUrl: '/dms/m/rotationpic/list',
      configs,
      params: { sortFiled: 'sort_num' },
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  mounted() {
    this.getConfigList('ads-table').then((res) => {
      res.map((v) => {
        const rowData = v;
        if (!rowData.field) {
          rowData.className = 'formatter-image';
        }
        return rowData;
      });
    });
  },
  methods: {
    setColumn(col) {
      const rowData = col;

      rowData.className = 'formatter-image';
      if (rowData.field === 'url') {
        rowData.type = 'html';
        rowData.formatter = ({ cellValue }) => `<img src="${cellValue}" />`;
      }

      if (rowData.field === 'createDate') {
        rowData.formatter = ({ row }) => `${row.createDate} ${row.createDateSecond}`;
      }

      if (rowData.field === 'updateDate') {
        rowData.formatter = ({ row }) => `${row.updateDate ? row.updateDate : ''} ${row.updateDateSecond ? row.updateDateSecond : ''}`;
      }
      return rowData;
    },
    modalClick({ val, row }) {
      this.formConfig = {
        edit: false,
      };
      if (val.code === 'edit') {
        this.modalConfig.title = '编辑';
        this.formConfig = {
          // ...val,
          ...row,
          edit: true,
        };
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.formConfig = {
          // ...val,
          ...row,
          edit: true,
        };
      } else {
        this.modalConfig.title = '新增';
      }
      if (val.code !== 'delete') this.openFull();
    },
  },
};
</script>
