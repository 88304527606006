<!--
 * @Author: yzr
 * @Date: 2020-11-24 14:24:20
 * @LastEditors: yzr
 * @LastEditTime: 2020-12-08 15:51:39
-->

<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import uploadMsg from './components';
import CheckOrg from './components/check_org.vue';

formCreate.component('uploadMsg', uploadMsg);
formCreate.component('CheckOrg', CheckOrg);

let timer = null;
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      // form内组件
      rule: [],
    };
  },

  async mounted() {
    await this.getFormRule('ads-add');

    this.getRule('url').props.limit = 1;
    this.getRule('url').props.accept = '.jpg,.jpeg,.png,.JPG,.JPEG,.PNG';
    // this.getRule('url').props.limitSize = 'mdm_product_pic_size';
    // this.getRule('url').props.limitAccept = 'mdm_product_pic_type';

    this.rule.map((item) => {
      const data = item;
      if (data.field === 'list') {
        data.props = {
          functionCode: '',
          selection: [],
          idKey: 'id',
          notView: this.formConfig.code !== 'view',
        };
        data.value = {
          orgs: [],
          nonOrgs: [],
        };
      }
      if (data.field === 'sortNum') {
        data.value = 1;
        data.props = {
          min: 1,
        };
      }

      // if (data.field === 'url') {
      //   data.props = {
      //     limit: 1,
      //     accept: '.jpg,.jpeg,.png,.JPG,.JPEG,.PNG',
      //   };
      // }

      if (data.field === 'msg') {
        data.props = {
          ...item.props,
          fileMsg: '只能上传jpg/png文件，且不超过2MB，建议图片长宽度：680px - 380px',
        };
      }

      return data;
    });

    this.reload(this.rule);
    this.initEditValue();
  },

  methods: {
    // 提交表单
    submit() {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        this.submitForm();
      }, 500);
    },

    // 创建请求
    submitForm() {
      const { edit, id } = this.formConfig;
      let requestUrl = '/dms/m/rotationpic/add';
      if (edit) {
        requestUrl = '/dms/m/rotationpic/edit';
      }

      const params = {
        ...this.getFormData(),
        urlPath: this.getFormData().url,
        urlPathPrefix: this.getFormData().url,
        areas: this.getFormData().list.orgs.map((v) => {
          const rowData = v;
          rowData.customerName = '';
          rowData.customerCode = '';
          rowData.containFlag = 1;
          delete rowData.id;
          return rowData;
        }),
        noAreas: this.getFormData().list.nonOrgs.map((v) => {
          const rowData = v;
          rowData.customerName = '';
          rowData.customerCode = '';
          rowData.containFlag = 0;
          delete rowData.id;
          return rowData;
        }),
      };
      delete params.list;
      if (edit) params.id = id;
      if (!params.remarks || !params.sortNum || !params.fileName || !params.url) return;
      request.post(requestUrl, params).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.$emit('onClose');
          this.$emit('onGetList');
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 编辑赋值
    initEditValue() {
      const { edit } = this.formConfig;
      if (!edit) {
        this.resetFields();
      } else {
        const {
          remarks, sortNum, fileName, url, noAreas, areas, targetUrl,
        } = this.formConfig;
        const list = {
          orgs: areas || [],
          nonOrgs: noAreas || [],
        };
        this.setValue({
          remarks,
          sortNum,
          fileName,
          url,
          list,
          targetUrl,
        });
      }
    },
  },
};
</script>
