<!--
 * @Author: yzr
 * @Date: 2020-12-08 10:53:01
 * @LastEditors: yzr
 * @LastEditTime: 2020-12-08 10:59:59
-->
<template>
  <div class="msg">{{fileMsg || '只能上传jpg/png文件，且不超过2MB'}}</div>
</template>

<script>
export default {
  name: 'uploadMsg',
  props: {
    fileMsg: String,
  },
};
</script>

<style scoped>
.msg {
  color: gray;
  margin-top: -25px;
}
</style>
